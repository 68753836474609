import React, { useEffect } from 'react'

const InArticleAd = () => {
  useEffect(() => {

    try {
      const adsbygoogle = window.adsbygoogle || []
      adsbygoogle.push({})
    } catch (e) {
      console.error(e)
    }

  }, [])

  return <ins
    className="adsbygoogle"
    style={{ display: 'block', textAlign: 'center' }}
    data-ad-layout="in-article"
    data-ad-format="fluid"
    data-ad-client="ca-pub-7943122633795545"
    data-ad-slot="6713201676">

  </ins>
}

export default InArticleAd